import React from 'react';
import { NavLink } from 'react-router-dom';

import infoSaverImage from 'src/assets/images/app-icon-infosaver.png';
import sfqImage from 'src/assets/images/app-icon-sfq.png';

import styles from './home-page.module.scss';

export const HomePage = () => {
  return (
    <section className={styles.welcomeArea}>
      <div className={styles.content}>
        <div className={styles.description}>
          <h1 className={styles.title}>
            Check out our mobile apps we're working on.
          </h1>

          <p className={styles.text}>
            We design and develop mobile apps that delight your users and grow
            your business. Enterprise-grade development combined with
            outstanding design.
          </p>
        </div>

        <div className={styles.appBlock}>
          <NavLink to="/infosaver" className={styles.appLink}>
            <div className={styles.appWrapper}>
              <div className={styles.appLogoWrapper}>
                <img
                  src={infoSaverImage}
                  className={styles.appImage}
                  alt="InfoSaver screens"
                />
              </div>

              <div className={styles.appContent}>
                <div className={styles.appTitle}>InfoSaver</div>
                <p>
                  A smart way to organize purchases, track expenses, scan QR and
                  Barcodes, store links
                </p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/sfq" className={styles.appLink}>
            <div className={styles.appWrapper}>
              <div className={styles.appLogoWrapper}>
                <img
                  src={sfqImage}
                  className={styles.appImage}
                  alt="Smoke-Free Quest screens"
                />
              </div>

              <div className={styles.appContent}>
                <div className={styles.appTitle}>Smoke-Free Quest</div>
                <p>
                  Embark on a smoke-free journey with our app. Track progress,
                  and stay motivated. Quit smoking step by step with our app.
                  Start your healthier life today!
                </p>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </section>
  );
};
