import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import clsx from 'clsx';

import styles from './terms-of-use-page.module.scss';

type TTermsOfUsePageProps = {
  date: string;
  appName: string;
};

export const TermsOfUsePage = ({ date, appName }: TTermsOfUsePageProps) => {
  // TODO move to hook
  const [locale, setLocale] = useState<string>('en');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const localeValue = searchParams.get('locale') ?? 'en';
    setLocale(localeValue);
  }, [searchParams]);

  const onLocaleChange = (value: 'en' | 'uk') => {
    setSearchParams({ locale: value });
    setLocale(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.localeSelectWrapper}>
        <span
          className={clsx(
            styles.localeSelectItem,
            locale === 'en' && styles.localeSelectItem__active
          )}
          onClick={() => onLocaleChange('en')}
        >
          EN
        </span>

        <span
          className={clsx(
            styles.localeSelectItem,
            locale === 'uk' && styles.localeSelectItem__active
          )}
          onClick={() => onLocaleChange('uk')}
        >
          UK
        </span>
      </div>

      {locale === 'en' ? (
        <>
          <h2 className={styles.header}>{appName} Terms of Use (EULA)</h2>

          <div className={styles.date}>Last Updated: {date}</div>

          <h3>1. Acceptance of Terms</h3>
          <p>
            By downloading, installing, or using {appName}, you agree to abide
            by the terms and conditions outlined in this End User License
            Agreement (EULA). If you do not agree with these terms, do not use
            the application.
          </p>

          <h3>2. License Grant</h3>
          <p>
            Subject to the terms of this EULA, Nestor.Studio grants you a
            personal, non-exclusive, non-transferable, limited license to use
            {appName} on your devices for personal or business use.
          </p>

          <h3>3. Restrictions</h3>
          <p>
            You may not: a. Modify, adapt, translate, reverse engineer,
            decompile, or disassemble {appName}.
            <br />
            b. Reproduce, distribute, or publicly display {appName} without
            prior written consent.
            <br />
            c. Use {appName} for any illegal or unauthorized purpose.
            <br />
            d. Remove or alter any copyright, trademark, or other proprietary
            notices from {appName}.
          </p>

          <h3>4. Privacy</h3>
          <p>
            Your use of {appName} is also governed by our Privacy Policy, which
            can be found at <Link to="/terms-of-use">Privacy Policy</Link>.
          </p>

          <h3>5. Updates and Maintenance</h3>
          <p>
            Nestor.Studio may release updates or patches to {appName} to improve
            functionality or address issues. You agree to promptly install such
            updates.
          </p>

          <h3>6. Termination</h3>
          <p>
            This license is effective until terminated by you or Nestor.Studio.
            You may terminate it at any time by uninstalling {appName}.
            Nestor.Studio may terminate the license at any time if you violate
            the terms of this EULA.
          </p>

          <h3>7. Disclaimer of Warranty</h3>
          <p>
            {appName} is provided "as is" without warranties of any kind, either
            expressed or implied. Nestor.Studio does not guarantee that
            {appName} will be error-free or uninterrupted.
          </p>

          <h3>8. Limitation of Liability</h3>
          <p>
            To the extent permitted by applicable law, Nestor.Studio shall not
            be liable for any direct, indirect, incidental, special, or
            consequential damages arising out of the use or inability to use
            {appName}.
          </p>

          <h3>9. Governing Law</h3>
          <p>
            This EULA is governed by and construed in accordance with the laws
            of Ukraine.
          </p>

          <h3>10. Contact Information</h3>
          <p>
            If you have any questions about this Terms of Use (EULA) or the use
            of the {appName} app, please,{' '}
            <a href="https://share-eu1.hsforms.com/1jw3eD57wRBquuZi1FnOp2A2d8346">
              contact us
            </a>
            .
          </p>

          <p>
            By using {appName}, you acknowledge that you have read and
            understood this EULA and agree to be bound by its terms.
          </p>
        </>
      ) : (
        <>
          <h2 className={styles.header}>
            {appName} Ліцензійна угода кінцевого користувача (EULA)
          </h2>

          <div className={styles.date}>Last Updated: {date}</div>

          <h3>1. Прийняття умов</h3>
          <p>
            Завантажуючи, встановлюючи чи використовуючи {appName}, ви
            погоджуєтеся дотримуватися умов і положень, визначених у цій
            ліцензійній угоді кінцевого користувача (EULA). Якщо ви не
            погоджуєтеся з цими умовами, не використовуйте додаток.
          </p>

          <h3>2. Надання ліцензії</h3>
          <p>
            З урахуванням умов цієї EULA, Nestor.Studio надає вам особисту, не
            ексклюзивну, не передавальну, обмежену ліцензію на використання
            {appName} на ваших пристроях для особистого чи бізнес-використання.
          </p>

          <h3>3. Обмеження</h3>
          <p>
            Ви не маєте права:
            <br />
            a. Модифікувати, адаптувати, перекладати, декомпілювати чи
            розібраність {appName}.
            <br />
            b. Відтворювати, розповсюджувати чи публічно відображати {
              appName
            }{' '}
            без попереднього письмового дозволу.
            <br />
            c. Використовувати {appName} для будь-яких незаконних або
            несанкціонованих цілей.
            <br />
            d. Видаляти чи змінювати будь-які торговельні марки, авторські чи
            інші примітки з {appName}.
          </p>

          <h3>4. Конфіденційність</h3>
          <p>
            Ваше використання {appName} також регулюється нашою Політикою
            конфіденційності, яку можна знайти за посиланням{' '}
            <Link to="/terms-of-use">Політика конфіденційності</Link>.
          </p>

          <h3>5. Оновлення та обслуговування</h3>
          <p>
            Nestor.Studio може випускати оновлення чи патчі для {appName} для
            поліпшення функціональності чи вирішення проблем. Ви погоджуєтеся
            вчасно встановлювати такі оновлення.
          </p>

          <h3>6. Завершення</h3>
          <p>
            Ця ліцензія діє до її завершення вами або Nestor.Studio. Ви можете
            завершити її у будь-який момент, видаливши {appName}. Nestor.Studio
            може завершити ліцензію в будь-який момент у разі порушення вами
            умов цієї EULA.
          </p>

          <h3>7. Відмова від гарантій</h3>
          <p>
            {appName} надається "як є" без будь-яких гарантій, які можна
            розуміти, чи виражених, чи припущених. Nestor.Studio не гарантує, що
            {appName} буде без помилок чи перерв.
          </p>

          <h3>8. Обмеження відповідальності</h3>
          <p>
            На вимогу чинного законодавства, Nestor.Studio не несе
            відповідальності за прямі, непрямі, випадкові, спеціальні чи
            наслідкові збитки, пов'язані з використанням чи неможливістю
            використання {appName}.
          </p>

          <h3>9. Законодавство</h3>
          <p>
            Ця EULA підпорядковується і тлумачиться відповідно до законів
            України.
          </p>

          <h3>10. Контактна інформація</h3>
          <p>
            Для отримання додаткової інформації чи вирішення питань, пов'язаних
            з цією (EULA) будь ласка,{' '}
            <a href="https://share-eu1.hsforms.com/1jw3eD57wRBquuZi1FnOp2A2d8346">
              зв'яжіться з нами
            </a>
            .
          </p>

          <p>
            Користуючись {appName}, ви визнаєте, що прочитали і розуміли цю EULA
            і погоджуєтеся дотримуватися її умов.
          </p>
        </>
      )}
    </div>
  );
};
