import React from 'react';
import { Link } from 'react-router-dom';

import sfqImage from '../../assets/images/sfq.png';
import styles from './smoke-free-quest.module.scss';

export const SmokeFreeQuestPage = () => {
  return (
    <div className={styles.root}>
      <h1 className={styles.header}>Smoke-Free Quest</h1>

      <div className={styles.promoWrapper}>
        <div className={styles.promo}>
          Embark on a smoke-free journey with our app.
        </div>

        <div className={styles.promo}>Track progress, and stay motivated.</div>

        <div className={styles.promo}>
          Quit smoking step by step with our app.
        </div>

        <div className={styles.promo}>Start your healthier life today!</div>
      </div>

      <img src={sfqImage} className={styles.sfqImage} alt="fireSpot" />

      <div className={styles.description}>
        <p>
          Ready to take control of your smoking habit? Join the Smoke-Free Quest
          and embark on a personalized journey to quit smoking for good! This
          app helps you track your daily cigarette consumption, gradually
          reducing the number of cigarettes per day based on your desired
          quitting period.
        </p>

        <p>
          With the widget, you'll stay motivated and can control the time when
          you can smoke the next cigarette according to your smoke-free plan
          without opening the application. Say goodbye to smoking addiction and
          hello to a healthier lifestyle!
        </p>

        <h4>Key Features:</h4>

        <ul>
          <li>
            Personalized Quitting Plan: Set your desired quitting period and the
            app will create a customized plan to help you reduce your cigarette
            consumption gradually.
          </li>
          <li>
            Daily Cigarette Tracker: Monitor your progress by tracking the
            number of cigarettes smoked each day, and see how far you've come!
          </li>
          <li>
            Widget: you'll stay motivated and can control the time when you can
            smoke the next cigarette according to your smoke-free plan.
          </li>
          <li>
            Progress Analytics: View detailed statistics on your progress,
            including the number of cigarettes smoked, time between cigarettes,
            and overall quit rate.
          </li>
        </ul>
        <div>
          By joining the Smoke-Free Quest app, you'll have a powerful tool to
          help you quit smoking for good. With its personalized approach,
          engaging design you'll be more likely to stay on track and achieve
          your goal of a smoke-free life!
        </div>

        <div className={styles.links}>
          Links to our
          <span> </span>
          <Link to="/sfq/privacy-policy">Privacy Policy</Link>
          <span> </span>
          <span>and</span>
          <span> </span>
          <Link to="/sfq/terms-of-use">Terms of Use</Link>
        </div>
      </div>
    </div>
  );
};
