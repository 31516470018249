import { Routes, Route } from 'react-router-dom';
import { ContactPage, HomePage, NotFoundPage, PrivacyPolicyPage, InfoSaverPage } from 'src/pages';
import { TermsOfUsePage } from 'src/pages/terms-of-use/terms-of-use-page';
import { SmokeFreeQuestPage } from 'src/pages/sfq/smoke-free-quest-page';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/infosaver' element={<InfoSaverPage />} />
      <Route path='/sfq' element={<SmokeFreeQuestPage />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/privacy-policy' element={<PrivacyPolicyPage date='04.01.2024' appName='InfoSaver' />} />
      <Route path='/sfq/privacy-policy' element={<PrivacyPolicyPage date='10.02.2024' appName='Smoke-Free Quest' />} />
      <Route path='/terms-of-use' element={<TermsOfUsePage date='11.01.2024' appName='InfoSaver' />} />
      <Route path='/sfq/terms-of-use' element={<TermsOfUsePage date='11.01.2024' appName='Smoke-Free Quest' />} />

      <Route path='/not-found' element={<NotFoundPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
