import { useScript } from 'src/hooks';

import styles from './contact-page.module.scss';

const onLoad = (): void => {
  window.hbspt.forms.create({
    region: 'eu1',
    portalId: '143144646',
    formId: '8f0dde0f-9ef0-441a-aeb9-98b51673a9d8',
    target: '#hbspt-form',
  });
};

export const ContactPage = () => {
  useScript({ targetSelector: '#hbspt-form', url: 'https://js-eu1.hsforms.net/forms/embed/v2.js', onLoad });

  return <div>
    <h2>Get in touch</h2>

    <div className={styles.root} id='hbspt-form'></div>
  </div>;
};
