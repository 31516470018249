import React from 'react';
import { Link } from 'react-router-dom';

import styles from './app-footer.module.scss';

export const AppFooter = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.links}>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-use">Terms of Use</Link>
        </div>

        <div>©2023 - 2024 Nestor.Studio. All rights reserved.</div>
      </div>
    </div>
  );
};
