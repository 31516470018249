import { useEffect, useState } from 'react';

type TUseScriptProps = {
  targetSelector: string
  url: string
  onLoad: () => void
}
export const useScript = ({ targetSelector, url, onLoad }: TUseScriptProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);


    script.addEventListener('load', () => {
      setIsLoaded(true);
      onLoad();
    });


    return () => {
      const root = document.querySelector(targetSelector);
      root?.remove();

      document.body.removeChild(script);
    };
  }, [onLoad, targetSelector, url]);


  return { isLoaded };
};
