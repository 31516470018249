import { AppNavigation, AppRoutes, AppContainer } from 'src/components';

import './App.scss';
import { AppFooter } from 'src/components/footer/app-footer';

function App() {
  return (
    <div className='app'>
      <AppNavigation />

      <AppContainer>
        <AppRoutes />
      </AppContainer>

      <AppFooter />
    </div>
  );
}

export default App;
