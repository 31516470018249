import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import styles from './privacy-policy-page.module.scss';

type TPrivacyPolicyPageProps = {
  date: string;
  appName: string;
};

export const PrivacyPolicyPage = ({
  date,
  appName,
}: TPrivacyPolicyPageProps) => {
  const [locale, setLocale] = useState<string>('en');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const localeValue = searchParams.get('locale') ?? 'en';
    setLocale(localeValue);
  }, [searchParams]);

  const onLocaleChange = (value: 'en' | 'uk') => {
    setSearchParams({ locale: value });
    setLocale(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.localeSelectWrapper}>
        <span
          className={clsx(
            styles.localeSelectItem,
            locale === 'en' && styles.localeSelectItem__active
          )}
          onClick={() => onLocaleChange('en')}
        >
          EN
        </span>

        <span
          className={clsx(
            styles.localeSelectItem,
            locale === 'uk' && styles.localeSelectItem__active
          )}
          onClick={() => onLocaleChange('uk')}
        >
          UK
        </span>
      </div>

      {locale === 'en' ? (
        <>
          <h2 className={styles.header}>{appName} App Privacy Policy</h2>

          <div className={styles.date}>Last Updated: {date}</div>

          <p>
            Thank you for using the {appName} app. This privacy policy explains
            how we collect, use, and protect your personal information when
            using the {appName} app.
          </p>

          <h3>Collection and Use of Information</h3>

          <p>
            We may collect and store limited personal information that you
            provide when adding discount cards and other information to the{' '}
            {appName} app. This information may include your name, email
            address, phone number, and other data you choose to provide.
          </p>

          <p>
            We use the collected information solely for the purpose of storing
            your discount cards and information for later viewing. We do not
            disclose this information to third parties without your written
            consent, except as required by law.
          </p>

          <h3>Protection of Information</h3>

          <p>
            We make reasonable efforts to ensure the security of your personal
            information. We employ various technical and organizational measures
            to protect your data from unauthorized access, loss, and misuse.
          </p>

          <h3>Cookies and Analytics</h3>

          <p>
            We may use cookies and similar technologies to collect anonymous
            information about the use of the app. This helps us improve the app
            and provide you with a better user experience.
          </p>

          <h3>Changes to the Privacy Policy</h3>

          <p>
            We may update this privacy policy from time to time, and we will
            notify you of any changes by posting a new version on our website or
            within the app. By continuing to use the app after such changes, you
            agree to the updated privacy policy.
          </p>

          <h3>Contact Information</h3>

          <p>
            If you have any questions about this privacy policy or the use of
            the {appName} app, please,
            {" "}
            <a href="https://share-eu1.hsforms.com/1jw3eD57wRBquuZi1FnOp2A2d8346">
              contact us
            </a>
            .
          </p>

          <div className={styles.date}>
            This privacy policy was last updated {date}.
          </div>
        </>
      ) : (
        <>
          <h2 className={styles.header}>
            Політика конфіденційності додатку {appName}
          </h2>

          <div className={styles.date}>Останнє оновлення: {date}</div>

          <p>
            Дякуємо за використання додатку {appName}. Ця політика
            конфіденційності пояснює, як ми збираємо, використовуємо та
            захищаємо вашу особисту інформацію під час використання додатку.
          </p>

          <h3>Збір та використання інформації</h3>

          <p>
            Ми можемо збирати та зберігати обмежену особисту інформацію, яку ви
            надаєте при додаванні дисконтних карток та іншої інформації до
            додатку {appName}. Ця інформація може включати ім'я, електронну
            адресу, номер телефону та інші дані, які ви вирішили надати.
          </p>

          <p>
            Ми використовуємо зібрану інформацію лише для цілей зберігання ваших
            дисконтних карток та інформації для перегляду пізніше. Ми не
            розкриваємо цю інформацію третім особам без вашої письмової згоди,
            крім випадків, передбачених законом.
          </p>

          <h3>Захист інформації</h3>

          <p>
            Ми прикладаємо розумні зусилля для забезпечення безпеки вашої
            особистої інформації. Ми використовуємо різні технічні та
            організаційні заходи для захисту ваших даних від несанкціонованого
            доступу, втрати та зловживання.
          </p>

          <h3>Cookies та аналітика</h3>

          <p>
            Ми можемо використовувати cookies та інші аналогічні технології для
            збору анонімної інформації про використання додатку. Це допомагає
            нам вдосконалювати додаток та надавати вам кращий досвід
            користувача.
          </p>

          <h3>Зміни до політики конфіденційності</h3>

          <p>
            Ми можемо час від часу оновлювати цю політику конфіденційності, і ми
            повідомимо вас про будь-які зміни, публікувавши нову версію нашої
            веб-сторінки або в додатку. Продовжуючи використовувати додаток
            після таких змін, ви погоджуєтеся з оновленою політикою
            конфіденційності.
          </p>

          <h3>Контактна інформація</h3>

          <p>
            Якщо у вас є питання щодо цієї політики конфіденційності або
            використання додатку {appName}, будь ласка,
            {" "}
            <a href="https://share-eu1.hsforms.com/1jw3eD57wRBquuZi1FnOp2A2d8346">
              зв'яжіться з нами
            </a>
            .
          </p>

          <div className={styles.date}>
            Ця політика конфіденційності була останнім разом оновлена {date}.
          </div>
        </>
      )}
    </div>
  );
};
