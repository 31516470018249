import { NavLink } from 'react-router-dom';

import styles from './app-navigation.module.scss';

export const AppNavigation = () => {
  return (
    <div className={styles.navigation}>
      <div className={styles.navigationBar}>
        <div className={styles.logo}>
          <NavLink to="/">
            <div>N E S T O R &nbsp;&nbsp; S T U D I O</div>
            <div className={styles.logoSubtitle}>Making life easier</div>
          </NavLink>
        </div>

        <nav className={styles.nav}>
          <ul>
            <li>
              <NavLink to="/infosaver">Info Saver</NavLink>
            </li>
            <li>
              <NavLink to="/sfq">Smoke-Free Quest</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    // <header className={styles.header}>
    //   <div className={styles.container}>
    //     <div className={styles.row}>
    //       <div className={styles.col}>
    //         <nav className='main-nav'>
    //           <NavLink to='/'>
    //             <FullLogo />
    //           </NavLink>
    //
    //           <ul className='nav'>
    //             <li><NavLink to='/infosaver'>Info Saver</NavLink></li>
    //             <li><NavLink to='/contact'>Contact</NavLink></li>
    //           </ul>
    //
    //           <a className='menu-trigger'>
    //             <span>Menu</span>
    //           </a>
    //         </nav>
    //       </div>
    //     </div>
    //   </div>
    // </header>
  );
};
