import React, { ReactElement } from 'react';

import styles from './app-container.module.scss';

type TAppContainer = {
  children: ReactElement
}
export const AppContainer = ({ children }: TAppContainer) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
};
