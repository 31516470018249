import React from 'react';

import clsx from 'clsx';

import appleStore from '../../assets/buttons/Download-on-the-App-Store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googlePlayEn from '../../assets/buttons/GetItOnGooglePlay_Badge_Web_color_English.png';
import googlePlayUk from '../../assets/buttons/GetItOnGooglePlay_Badge_Web_color_Ukranian.png';
import mainImage from '../../assets/images/landing_info_saver_main_image.png';
import styles from './info-saver-pade.module.scss';

export const InfoSaverPage = () => {
  const isUkraine = navigator.language === 'uk';

  return (
    <div className={styles.root}>
      <div className={styles.hero}>
        <div className={clsx(styles.column, styles.textColumn)}>
          <h1 className={styles.title}>I N F O &nbsp;&nbsp; S A V E R</h1>
          <div className={styles.subtitle}>
            A smart way to organize purchases, track expenses, scan QR and
            Barcodes, store links
          </div>
          <div className={styles.marketWrapper}>
            <a
              className={styles.marketLink}
              href="https://apple.co/48kvkk5"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={appleStore}
                className={styles.marketButton}
                alt="Apple Store"
                onClick={() => {}}
              />
            </a>

            <a
              className={styles.marketLink}
              href="https://play.google.com/store/apps/details?id=studio.nestor.infosaver&hl=uk"
              target="_blank"
              rel="noreferrer"
            >
              {isUkraine ? (
                <img
                  src={googlePlayUk}
                  className={styles.marketButton}
                  alt="Google Play"
                />
              ) : (
                <img
                  src={googlePlayEn}
                  className={styles.marketButton}
                  alt="Google Play"
                />
              )}
            </a>
          </div>
        </div>

        <div className={clsx(styles.column, styles.imageColumn)}>
          <div className={styles.imageWrapper}>
            <img
              src={mainImage}
              className={styles.mainImage}
              alt="Info Saver screens"
            />
          </div>
        </div>
      </div>

      <div className={styles.featureBlock}>
        <div className={styles.featureWrapper}>
          <div className={styles.featureTitle}>Transaction Management</div>
          <p>
            Organize your purchases and expenses into lists, each list can
            contain individual expenses or sublists.
          </p>
        </div>
        <div className={styles.featureWrapper}>
          <div className={styles.featureTitle}>QR & Barcode Scanning</div>
          <p>
            Easily scan and save product information using the built-in barcode
            scanning feature.
          </p>
        </div>
        <div className={styles.featureWrapper}>
          <div className={styles.featureTitle}>Save Useful Links</div>
          <p>
            Discover interesting resources online and save them to InfoSaver for
            quick access and later use.
          </p>
        </div>
      </div>
    </div>
  );
};
